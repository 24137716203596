import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import "./index.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import Layout from "./layout.tsx";
import * as Sentry from "@sentry/react";
import Runes from "./routes/runes.tsx";
import ICP from "./routes/icp.tsx";
import Ckbtc from "./routes/ckbtc.tsx";

Sentry.init({
  dsn: "https://be2f0f8d4e8949d28f17cdcdb1494e45@o1416986.ingest.us.sentry.io/6758678",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to="/runes" replace />,
      },
      {
        path: "runes/",
        element: <Runes />,
        children: [
          {
            path: ":tab",
            element: <Runes />,
          },
        ],
      },
      {
        path: "icp/",
        element: <ICP />,
        children: [
          {
            path: ":tab",
            element: <ICP />,
          },
        ],
      },
      {
        path: "ckbtc",
        element: <Ckbtc />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>,
);
