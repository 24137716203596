import {
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import Transfer from "./tabs/Transfer";
import TicketHistory from "./components/TicketHistory";
import Burn from "./tabs/Burn";
import { ChainID, OmnityWidgetProps, TabAction } from "@types";
import { useHubContext } from "./context/OmnityHubContext";
import Footer from "@components/Footer";
import { BurnContextProvider } from "./context/BurnContext";
import { ChainSelectProvider } from "./context/ChainContext";
import { TransferContextProvider } from "./context/TransferContext";
import { TransferTokenContextProvider } from "./context/TransferTokenContext";
import { BurnTokenContextProvider } from "./context/BurnTokenContext";
import AddRunes from "./tabs/AddRunes";
import { AddRunesContextProvider } from "./context/AddRunesContext";
import Mint from "./tabs/Mint";
import { MintContextProvider } from "./context/MintContext";
import { MintTokenContextProvider } from "./context/MintTokenContext";

const TABS = [
  TabAction.Transfer,
  TabAction.Mint,
  TabAction.Burn,
  TabAction.AddRunes,
];

export default function Omnity({
  customs,
  width = 464,
  tab,
  sourceChain,
  targetChain,
  tokenIds,
  reversible,
  isPeerToPeer,
  onTabChange,
}: OmnityWidgetProps) {
  const { onTabActionChange } = useHubContext();

  let tabs = TABS;
  if (customs === ChainID.sICP) {
    tabs = [TabAction.Transfer];
  }
  return (
    <VStack
      id="omnity"
      w={{ base: "100%", md: width }}
      gap="2px"
      px={{ base: 2, md: 0 }}
      mt={{ base: 6, md: 0 }}
    >
      <HStack w="100%" justifyContent="flex-end">
        <TicketHistory />
      </HStack>
      <Tabs
        variant="soft-rounded"
        colorScheme="pink"
        w="100%"
        index={TABS.indexOf(tab ?? TabAction.Transfer)}
      >
        <TabList gap={{ base: 2, md: 4 }}>
          {tabs.map((tab) => {
            return (
              <Tab
                key={tab}
                color="gray.100"
                onClick={() => {
                  onTabActionChange && onTabActionChange(tab);
                  onTabChange && onTabChange(tab);
                }}
                pos="relative"
              >
                {tab}
              </Tab>
            );
          })}
        </TabList>
        <TabPanels mt={2}>
          <TabPanel p={0}>
            <TransferContextProvider
              sourceChain={sourceChain}
              targetChain={targetChain}
              tokenIds={tokenIds}
              reversible={reversible}
              isPeerToPeer={isPeerToPeer}
            >
              <ChainSelectProvider>
                <TransferTokenContextProvider>
                  <Transfer width={width} />
                </TransferTokenContextProvider>
              </ChainSelectProvider>
            </TransferContextProvider>
          </TabPanel>
          <TabPanel p={0}>
            <MintContextProvider tokenIds={tokenIds} sourceChain={sourceChain}>
              <ChainSelectProvider>
                <MintTokenContextProvider>
                  <Mint width={width} />
                </MintTokenContextProvider>
              </ChainSelectProvider>
            </MintContextProvider>
          </TabPanel>
          <TabPanel p={0}>
            <BurnContextProvider tokenIds={tokenIds}>
              <ChainSelectProvider>
                <BurnTokenContextProvider>
                  <Burn width={width} />
                </BurnTokenContextProvider>
              </ChainSelectProvider>
            </BurnContextProvider>
          </TabPanel>
          <TabPanel p={0}>
            <AddRunesContextProvider>
              <AddRunes width={width} />
            </AddRunesContextProvider>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Footer />
    </VStack>
  );
}
