import { EventType, WalletType } from '../../types'
import * as PubSub from 'pubsub-js'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  HStack,
  Image,
  Text,
  Badge,
  VStack,
  useColorModeValue,
  ModalCloseButton,
} from '@chakra-ui/react'
import { OkxWalletLogo, UnisatWalletLogo } from './Logos'

const wallets = [
  {
    type: WalletType.OKX,
    logo: OkxWalletLogo,
    detected: typeof window !== 'undefined' && !!window.okxwallet,
    onClick: async () => {
      if (typeof window === 'undefined') {
        return
      }
      if (!window.okxwallet) {
        // open okx wallet website
        window.open('https://www.okx.com/web3', '_blank')
        return
      }
      const okxAccount = await window.okxwallet?.bitcoin.connect()
      if (!okxAccount) {
        throw new Error('No Bitcoin Account')
      }
      PubSub?.publish(EventType.ON_CONNECT, {
        address: okxAccount.address,
        connector: WalletType.OKX,
      })
    },
  },
  {
    type: WalletType.Unisat,
    logo: UnisatWalletLogo,
    detected: typeof window !== 'undefined' && !!window.unisat,
    onClick: async () => {
      if (typeof window === 'undefined') {
        return
      }

      if (!window.unisat) {
        // open unisat wallet website
        window.open('https://unisat.io', '_blank')
        return
      }
      const addresses = await window.unisat?.requestAccounts()
      if (!addresses.length) {
        throw new Error('No Bitcoin Account')
      }
      PubSub?.publish(EventType.ON_CONNECT, {
        address: addresses[0],
        connector: WalletType.Unisat,
      })
    },
  },
]

export default function BTCWalletKitModal({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) {
  const textColor = useColorModeValue('gray.800', 'gray.100')
  const boxWrapperBg = useColorModeValue('gray.200', 'gray.800')

  return (
    <Modal isOpen={open} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={8}>
        <ModalHeader color={textColor}>Connect Bitcoin Wallet</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} color={textColor}>
          {wallets.map((wallet) => {
            return (
              <HStack
                key={wallet.type}
                gap={4}
                px={4}
                py={2}
                borderRadius={8}
                cursor="pointer"
                onClick={() => {
                  wallet.onClick()
                  onClose()
                }}
                _hover={{
                  bg: boxWrapperBg,
                }}
              >
                <Image
                  src={wallet.logo}
                  alt={wallet.type}
                  boxSize={16}
                  borderRadius={8}
                />
                <VStack alignItems="flex-start" gap={0}>
                  <Text fontWeight={600} fontSize={24} color={textColor}>
                    {wallet.type}
                  </Text>
                  {wallet.detected && (
                    <Badge colorScheme="purple">Detected</Badge>
                  )}
                </VStack>
              </HStack>
            )
          })}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
