import {
  Box,
  Button,
  ButtonGroup,
  chakra,
  Collapse,
  HStack,
  Image,
  keyframes,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useClipboard,
  useColorModeValue,
  useDisclosure,
  usePrefersReducedMotion,
  useSteps,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useHubContext } from "../../context/OmnityHubContext";
import { useAddRunesContext } from "../../context/AddRunesContext";
import { useEffect, useState } from "react";
import CloseButtonForModal from "@components/common/CloseButtonForModal";
import { useICPWalletKit } from "@wallet-kits/icp-wallet-kit";
import { getChainIdFromName } from "src/utils/chains";
import ChainLogo from "@components/ChainLogo";
import { formatUnits } from "../../utils/format";
import { ADD_RUNES_SUFFIX, ICP_DECIMALS } from "../../utils/constants";
import ServiceFactory from "@services/index";
import { Copy } from "lucide-react";

const pulse = keyframes`
  from { box-shadow: 0 0 0 0px rgba(152, 92, 221, 0.5); }
  to { box-shadow: 0 0 0 10px rgba(152, 92, 221, 0); }
`;

enum AddRunesFlow {
  Preview = "Preview",
  Processing = "Processing",
  Processed = "Processed",
  Done = "Done",
}

const ModalTitle = {
  [AddRunesFlow.Preview]: "",
  [AddRunesFlow.Processing]: "",
  [AddRunesFlow.Processed]: "",
  [AddRunesFlow.Done]: "",
};

const CopyIcon = chakra(Copy);

export default function ConfirmAddRunes() {
  const [flow, setFlow] = useState(AddRunesFlow.Preview);
  const [needRetry, setNeedRetry] = useState(false);
  const [contractAddr, setContractAddr] = useState("");

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { hubService, chains } = useHubContext();
  const {
    selectedRune,
    token,
    selectedChain,
    symbol,
    onSelectRune,
    onSelectChain,
    onSymbolChange,
    serviceFee,
  } = useAddRunesContext();

  const steps =
    (token ? hubService?.getAddChainSteps() : hubService?.getAddRunesSteps()) ??
    [];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const textColor = useColorModeValue("gray.800", "gray.100");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const prefersReducedMotion = usePrefersReducedMotion();
  const toast = useToast();
  const { address, transfer, createActor } = useICPWalletKit();

  const animation = prefersReducedMotion
    ? undefined
    : `${pulse} infinite 1s linear`;

  const _onClose = () => {
    onClose();
    onSymbolChange?.("");
    onSelectChain?.(undefined);
    onSelectRune?.(undefined);
  };

  const onConfirm = async () => {
    try {
      setFlow(AddRunesFlow.Processing);
      setNeedRetry(false);

      if (!token) {
        if (!symbol) {
          throw new Error("Symbol is required");
        }
      }
      if (!selectedChain) {
        throw new Error("Chain is required");
      }
      if (!selectedRune) {
        throw new Error("Runes is required");
      }
      if (!hubService) {
        throw new Error("HubService is required");
      }
      if (!address) {
        throw new Error("Please connect wallet first");
      }
      const dest_chain = getChainIdFromName(selectedChain)[0];
      if (!dest_chain) {
        throw new Error("Invalid chain");
      }
      let result: boolean;
      if (!token) {
        result = await hubService.onAddRunes({
          address,
          icon: selectedRune.icon ?? "",
          symbol: symbol + ADD_RUNES_SUFFIX,
          rune_id: selectedRune.rune_id,
          setStep: setActiveStep,
          dest_chain,
          transfer,
          createActor,
        });
      } else {
        result = await hubService.onAddChainForRunes({
          dest_chain,
          token_id: token.token_id,
          address,
          setStep: setActiveStep,
          transfer,
          createActor,
        });
      }
      if (result) {
        setFlow(AddRunesFlow.Processed);
        toast({
          description: `Adding ${token ? "chain" : "runes"}`,
          status: "success",
          duration: 3000,
        });
      } else {
        setNeedRetry(true);
      }
    } catch (error) {
      setNeedRetry(true);
      toast({
        description: (error as Error).message,
        status: "error",
        duration: 3000,
      });
    }
  };

  const rows = [
    {
      title: "Symbol",
      value: symbol + (token ? "" : ADD_RUNES_SUFFIX),
    },
    {
      title: "Name",
      value: selectedRune?.spaced_rune,
    },
    {
      title: "Rune ID",
      value: selectedRune?.rune_id,
    },
    {
      title: "Chain",
      value: (
        <HStack>
          <ChainLogo chain={selectedChain} size={20} />
          <Text>{selectedChain}</Text>
        </HStack>
      ),
    },
    {
      title: "Fee",
      value: `${formatUnits(token ? serviceFee?.add_chain_fee : serviceFee?.add_token_fee, ICP_DECIMALS)} ICP`,
    },
  ];

  const { onCopy } = useClipboard(contractAddr);
  if (contractAddr) {
    rows.push({
      title: "Contract Address",
      value: (
        <HStack>
          <Text color="blue.500" maxW={100} isTruncated>
            {contractAddr}
          </Text>
          <CopyIcon
            size={16}
            cursor="pointer"
            onClick={() => {
              onCopy();
              toast({
                title: "Copied to clipboard",
                status: "success",
                duration: 2000,
              });
            }}
          />
        </HStack>
      ),
    });
  }

  useEffect(() => {
    if (
      selectedRune?.spaced_rune &&
      selectedChain &&
      flow === AddRunesFlow.Processed
    ) {
      async function checkIfTokenAdded() {
        try {
          if (!selectedRune) {
            throw new Error("Rune not found");
          }
          const adding_chain = getChainIdFromName(selectedChain)[0];
          const chain = chains.find((c) => c.chain_id === adding_chain);
          if (!chain) {
            throw new Error("Chain not found");
          }
          const service = ServiceFactory.createService(chain);
          const tokenList = await service.getTokenList();
          const tokenId = `Bitcoin-runes-${selectedRune?.spaced_rune}`;
          const _token = tokenList.find((t) => t.token_id === tokenId);
          if (_token) {
            setFlow(AddRunesFlow.Done);
            setContractAddr(_token.id);
          }
        } catch (error) {}
      }
      checkIfTokenAdded();
      const interval = setInterval(checkIfTokenAdded, 10000);
      return () => clearInterval(interval);
    }
  }, [selectedRune?.spaced_rune, selectedChain, flow, chains.length]);

  return (
    <>
      <Button
        colorScheme="blue"
        cursor="pointer"
        w="98%"
        fontSize={22}
        py={8}
        borderRadius={8}
        onClick={onOpen}
      >
        Confirm
      </Button>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={_onClose}
      >
        <ModalOverlay />
        <ModalContent
          p={0}
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          {flow === AddRunesFlow.Preview && <CloseButtonForModal />}
          <ModalHeader>{ModalTitle[flow]}</ModalHeader>
          <ModalBody pb={6}>
            <VStack gap={{ base: 2, md: 1 }}>
              <Image
                src={selectedRune?.icon}
                w={100}
                h={100}
                borderRadius="full"
              />
              <VStack w="100%" gap={0}>
                {rows.map((row) => {
                  return (
                    <HStack
                      key={row.title}
                      w="100%"
                      justifyContent="space-between"
                      py={2}
                      borderBottomWidth={0.5}
                      borderBottomColor={borderColor}
                    >
                      <Text>{row.title}</Text>
                      <Text>{row.value}</Text>
                    </HStack>
                  );
                })}
              </VStack>
              {flow === AddRunesFlow.Preview && (
                <Button
                  colorScheme="blue"
                  cursor="pointer"
                  w="98%"
                  fontSize={18}
                  py={6}
                  mt={6}
                  onClick={onConfirm}
                >
                  Add
                </Button>
              )}
              <HStack w="98%" alignItems="flex-start">
                <Collapse
                  in={flow === AddRunesFlow.Processing}
                  animateOpacity
                  style={{ width: "100%" }}
                >
                  <Stepper
                    index={activeStep}
                    w="98%"
                    px={4}
                    py={4}
                    orientation="vertical"
                    gap={0}
                  >
                    {steps.map((step, index) => {
                      const isActive = index === activeStep;
                      return (
                        <Step key={index}>
                          <StepIndicator
                            animation={isActive ? animation : undefined}
                            boxShadow={
                              isActive ? "0px 0px 1px 1px #0000001a" : ""
                            }
                          >
                            <StepStatus
                              complete={<StepIcon />}
                              incomplete={<StepNumber />}
                              active={<StepNumber />}
                            />
                          </StepIndicator>

                          <Box flexShrink="0" pb={4}>
                            <StepTitle>{step.title}</StepTitle>
                            <StepDescription>
                              {step.description}
                            </StepDescription>
                          </Box>

                          <StepSeparator />
                        </Step>
                      );
                    })}
                  </Stepper>
                </Collapse>
              </HStack>

              {(flow === AddRunesFlow.Processed ||
                flow === AddRunesFlow.Done) && (
                <VStack w="98%">
                  <Button
                    w="90%"
                    colorScheme="blue"
                    cursor="pointer"
                    onClick={flow === AddRunesFlow.Done ? _onClose : undefined}
                    isLoading={flow === AddRunesFlow.Processed}
                    loadingText="Adding"
                  >
                    Done
                  </Button>
                  <Text fontSize={14} textAlign="center" color="gray.500">
                    {flow === AddRunesFlow.Done
                      ? `${token ? "Chain" : "Runes"} added successfully.`
                      : `${token ? "Chain" : "Runes"} will be added in minutes, stay
                    tuned.`}
                  </Text>
                </VStack>
              )}

              {needRetry && (
                <ButtonGroup w="98%" spacing="6">
                  <Button
                    colorScheme="blue"
                    cursor="pointer"
                    variant="outline"
                    onClick={_onClose}
                    flex={1}
                  >
                    Cancel
                  </Button>
                  <Button
                    colorScheme="blue"
                    cursor="pointer"
                    onClick={onConfirm}
                    flex={1}
                  >
                    Retry
                  </Button>
                </ButtonGroup>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
