import { Text, VStack } from "@chakra-ui/react";
import WidgetCkbtc from "@omnity/ckbtc";

export default function Ckbtc() {
  return (
    <VStack w={{ base: "100%" }}>
      <Text fontSize={{ base: 18, md: 24 }} mb={4} fontWeight={300}>
        Omnity Hub - ckBTC
      </Text>
      <WidgetCkbtc />
    </VStack>
  );
}
