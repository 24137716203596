import {
  Box,
  ChakraProvider,
  extendTheme,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Switch,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { BridgeDirection } from "./types";
import Wallets from "./components/Wallets";
import {
  BTCWalletProvider,
  useBTCWalletKit,
} from "./wallet-kits/btc-wallet-kit";
import { formatUnits, readableNumber } from "./utils/format";
import { BTC_DECIMALS } from "./utils/constants";
import Confirm from "./components/Confirm";
import ConnectTargetWallet from "./components/ConnectTargetWallet";
import {
  OsmosisWalletProvider,
  useOsmosisWalletKit,
} from "./wallet-kits/osmosis-wallet-kit";
import { WidgetProvider, useWidgetContext } from "./context/WidgetContext";
import TransferButton from "./components/TransferButton";
import History from "@components/History";

const width = 560;

function WidgetCkbtc() {
  const {
    useConnectWalletForReceiver,
    targetAddr,
    direction,
    amount,
    onRevert,
    onToggleConnectWalletForReceiver,
    onAmountChange,
    onTargetAddrChange,
  } = useWidgetContext();
  const { balance: btcBalance } = useBTCWalletKit();
  const { balance: btcDenomBalance } = useOsmosisWalletKit();

  const inputTextColor = useColorModeValue("gray.800", "gray.200");
  const boxWrapperBg = useColorModeValue("#eee", "gray.700");

  const balance =
    direction === BridgeDirection.Transfer
      ? btcBalance
        ? BigInt(btcBalance.confirmed)
        : BigInt(0)
      : btcDenomBalance;

  return (
    <VStack w={{ base: "100%", md: width }} gap={2} px={{ base: 4, md: 0 }}>
      <History />
      <VStack
        p={{ base: 4, md: 6 }}
        borderRadius={8}
        gap={4}
        bg="gray.800"
        color="#eee"
        w="100%"
        mb={4}
      >
        <Wallets direction={direction} onRevert={onRevert} />

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={600}>Receiver</Text>
            <HStack>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="connected-target-addr" mb="0">
                  use connected wallet
                </FormLabel>
                <Switch
                  id="connected-target-addr"
                  isChecked={useConnectWalletForReceiver}
                  onChange={onToggleConnectWalletForReceiver}
                />
              </FormControl>
            </HStack>
          </HStack>
          <HStack w="100%" borderRadius={4}>
            {((useConnectWalletForReceiver && targetAddr) ||
              !useConnectWalletForReceiver) && (
              <Input
                color={inputTextColor}
                bg={boxWrapperBg}
                py={4}
                px={2}
                lineHeight={1}
                borderWidth={0}
                fontFamily="monospace"
                height="100%"
                outline="none"
                placeholder={
                  direction === BridgeDirection.Transfer
                    ? "Osmosis Address"
                    : "Bitcoin Address"
                }
                value={targetAddr}
                onChange={(e) =>
                  !useConnectWalletForReceiver &&
                  onTargetAddrChange &&
                  onTargetAddrChange(e.target.value)
                }
                _focus={{
                  borderWidth: 0,
                  outline: "none",
                  boxShadow: "none",
                }}
              />
            )}
            <ConnectTargetWallet />
          </HStack>
        </VStack>

        {/* <VStack w="100%" alignItems="flex-start" gap={1}>
          <Text fontWeight={600}>ckBTC deposit address</Text>
          <HStack
            w="100%"
            bg="gray.700"
            alignItems="flex-start"
            borderRadius={4}
            px={4}
            py={4}
            fontFamily="monospace"
          >
            {retrievingCkbtcAddr ? (
              <HStack>
                <Spinner size="sm" />
                <Text color="gray.400">Retrieving ckBTC address...</Text>
              </HStack>
            ) : (
              <Text
                color="blue.400"
                maxW={window.innerWidth * 0.78}
                textAlign="left"
              >
                {ckbtcAddr || "Missing Osmosis address"}
              </Text>
            )}
          </HStack>
        </VStack> */}

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <Text fontWeight={600}>Amount</Text>
          <VStack w="100%" bg="gray.700" borderRadius={4} px={4} py={0}>
            <HStack w="100%" py={2}>
              <Input
                placeholder="0"
                color="gray.200"
                px={0}
                flex={1}
                value={amount}
                fontSize={36}
                py={1}
                lineHeight={1}
                fontWeight={600}
                borderWidth={0}
                height="100%"
                outline="none"
                _focus={{
                  borderWidth: 0,
                  outline: "none",
                  boxShadow: "none",
                }}
                onChange={(e) => {
                  const newValue = e.target.value;
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  const [_, fraction] = newValue.split(".");
                  if (fraction && fraction.length > BTC_DECIMALS) {
                    return;
                  }
                  if (/^\d+(\.)?(\d+)?$/.test(newValue) || newValue === "") {
                    onAmountChange && onAmountChange(e.target.value);
                  }
                }}
              />
            </HStack>
          </VStack>
          <HStack
            w="100%"
            alignItems="center"
            fontSize={16}
            justifyContent="flex-end"
          >
            <Text fontFamily="monospace">
              Balance: {readableNumber(formatUnits(balance, BTC_DECIMALS), 7)}
            </Text>
            <Box
              bg="#008cd5"
              px={2}
              borderRadius={4}
              cursor="pointer"
              onClick={() =>
                onAmountChange &&
                onAmountChange(formatUnits(balance, BTC_DECIMALS))
              }
            >
              <Text color="white" fontSize={14} fontWeight={600}>
                Max
              </Text>
            </Box>
          </HStack>
        </VStack>
      </VStack>

      {direction === BridgeDirection.Transfer ? (
        <TransferButton />
      ) : (
        <Confirm />
      )}
    </VStack>
  );
}

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};
const theme = extendTheme({ config });

export default () => {
  return (
    <ChakraProvider theme={theme}>
      <BTCWalletProvider>
        <OsmosisWalletProvider>
          <WidgetProvider>
            <WidgetCkbtc />
          </WidgetProvider>
        </OsmosisWalletProvider>
      </BTCWalletProvider>
    </ChakraProvider>
  );
};
